import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const Counter = ({ end }) => {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  return (
    <CountUp end={ end } start={ viewPortEntered ? null : 0 }>
      {({ countUpRef }) => (
        <VisibilitySensor
          active={ !viewPortEntered }
          onChange={ (isVisible) => {
            if (isVisible) {
              setViewPortEntered(true);
            }
          } }
          delayedCall
        >
          <div className="text-primary text-center display-3" ref={ countUpRef } />
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

Counter.propTypes = {
  end: PropTypes.number,
};

Counter.defaultProps = {
  end: 0
};

export default Counter;