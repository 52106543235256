import { mdiFilePdf } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {
  Element
} from 'react-scroll';

import Counter from '../components/Counter';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

const IntroWithCountersSection = ({
  title, description, fundingIndications, numbers, bgColorClassName, fontColorClassName, lineDecorationClassName, certificates
}) => (
  <Container className={ `${ bgColorClassName } ${ fontColorClassName } py-5 my-0` } fluid>
    <Element name="count-section" />
    <Container className="d-flex flex-column">
      { title && (
      <Row className="justify-content-md-center order-1" noGutters>
        <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 5 } xl={ 5 } className={ `text-center pt-1 col-xxl-4 line-decoration ${ lineDecorationClassName }` }>
          <h3 className="text-break">{title}</h3>
        </Col>
      </Row>
      )}
      {
        description && (
        <Row className="justify-content-md-center order-2">
          <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 10 } xl={ 10 } className="text-center col-xxl-8">
            {description}
          </Col>
        </Row>
        )
      }
      {
        fundingIndications && (
          <Row className="justify-content-md-center order-sm-0 order-lg-3 my-4 my-lg-5">
            <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 } className="d-flex align-items-center justify-content-center">
              <Row className="justify-content-center">
                {fundingIndications.map((logo, i) => (
                  <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 } xl={ 6 } className="d-flex align-items-center justify-content-center my-3">
                    <a
                      href={ logo.url ? logo.url : '' }
                      style={{ margin: 'auto' }}
                      key={ i }
                    >
                      <PreviewCompatibleImage
                        imageInfo={{ image: logo.image }}
                        imageStyle={{ width: '250px' }}
                        shouldObjectFitContain
                      />
                    </a>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        )
      }
      {
        numbers && (
        <Row className="justify-content-md-center mt-5 mt-lg-0 order-4">
          {numbers.map((keyNumber) => (
            <Col key={ keyNumber.text } className="counter-number" xs={ 12 } sm={ 12 } md={ 6 } lg={ 3 } xl={ 3 }>
              <Counter end={ keyNumber.number } />
              <p className="text-center h4 text-dark font-weight-normal">{keyNumber.text}</p>
            </Col>
          ))}
        </Row>
        )
      }
      {certificates && (
        <Row className="justify-content-md-center mt-5 order-5">
          {certificates.map((certificate, i) => (
            <Col key={ i } xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 } xl={ 4 } className="d-flex align-items-center mt-4 flex-column">
              <PreviewCompatibleImage
                imageInfo={{ image: certificate.image }}
                imageStyle={{ width: '200px', height: '130px', }}
              />
              {certificate.pdfFiles && certificate.pdfFiles.filter((file) => file.source || file.downloadText).map((file, j) => (
                <a
                  href={ file.source && file.source.publicURL ? file.source.publicURL : '' }
                  target="_blank"
                  rel="noreferrer"
                  className="d-flex mt-3 justify-content-center"
                  key={ j }
                >
                  <Icon path={ mdiFilePdf } size={ 1 } /><span>{file.downloadText}</span>
                </a>
              ))}
            </Col>
          ))}
        </Row>
      )}
    </Container>
  </Container>
);

IntroWithCountersSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  fundingIndications: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.objectOf(PropTypes.object),
    url: PropTypes.string
  })),
  numbers: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.number,
    text: PropTypes.string
  })),
  bgColorClassName: PropTypes.string,
  fontColorClassName: PropTypes.string,
  lineDecorationClassName: PropTypes.string,
  certificates: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.objectOf(PropTypes.object),
    pdfFiles: PropTypes.arrayOf(PropTypes.object)
  }))
};

IntroWithCountersSection.defaultProps = {
  title: '',
  description: '',
  fundingIndications: undefined,
  numbers: undefined,
  bgColorClassName: '',
  fontColorClassName: '',
  lineDecorationClassName: 'line-decoration-dark',
  certificates: undefined
};

export default IntroWithCountersSection;